import React from "react";

import Box from "../../commons/Box";
import ArticleRenderer from "../../commons/Article/Renderer";

export default function RichTextBlock({ _rawRichText, ...otherProps }) {
  return (
    <Box
      as="section"
      variant="styles.article"
      sx={{ px: "gridGap", mb: [5, 7] }}
    >
      <ArticleRenderer blocks={_rawRichText} />
    </Box>
  );
}
